import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
	faArrowDown,
	faCalendarDays,
	faConciergeBell,
	faBars,
	faTimes,
	faStars,
	faRulerCombined,
	faUsers,
	faBlanket,
} from '@fortawesome/pro-light-svg-icons';

import {
	faChevronDown,
	faChevronUp,
	faCircleExclamation,
	faCheckCircle,
	faConciergeBell as faConciergeBellRegular,
	faCreditCard,
	faEnvelope,
	faGift,
	faHotel,
	faPhone,
	faWineGlass,
} from '@fortawesome/pro-regular-svg-icons';

import {
	faFacebook,
	faInstagram,
	faLinkedin,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faXTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
	faStar,
	faStarHalf,
	faMinus,
	faPlus,
	faCheck,
	faChevronDown as faChevronDownSolid,
	faXmark,
} from '@fortawesome/pro-solid-svg-icons';

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false;

library.add(
	faArrowDown,
	faBars,
	faBlanket,
	faCalendarDays,
	faCheck,
	faChevronDown,
	faChevronDownSolid,
	faChevronUp,
	faCheckCircle,
	faConciergeBell,
	faConciergeBellRegular,
	faCreditCard,
	faEnvelope,
	faGift,
	faHotel,
	faCircleExclamation,
	faTimes,
	faMinus,
	faPlus,
	faFacebook,
	faInstagram,
	faLinkedin,
	faPhone,
	faPinterest,
	faSoundcloud,
	faSpotify,
	faStars,
	faTiktok,
	faTwitter,
	faWhatsapp,
	faYoutube,
	faStar,
	faStarHalf,
	faRulerCombined,
	faUsers,
	faWineGlass,
	faXmark,
	faXTwitter,
);

export default defineNuxtPlugin(({ vueApp }) => {
	vueApp.component('font-awesome-icon', FontAwesomeIcon, {});
});
