/**
 * Fetch data from the backend API, or from the cache if available.
 *
 * @param {String} endpoint The API endpoint
 * @param {*} opts The options
 *
 * @returns {*} The useFetch method with the API base URL preconfigured
 */
export function useWebsiteFetch(endpoint, opts = { refreshCache: false }) {
	const config = useRuntimeConfig();

	let baseURL;
	try {
		baseURL = new URL(config.public.apiBaseURL).href;
		if (!baseURL.startsWith('http://') && !baseURL.startsWith('https://')) {
			throw new Error();
		}
	} catch (_e) {
		throw createError({
			statusCode: 500,
			message: 'API base URL is not correctly configured',
			fatal: true,
		});
	}

	if (opts.key && !opts.refreshCache) {
		let cachedData = useNuxtData(opts.key);

		if (cachedData.data.value) {
			return cachedData;
		}
	}

	return useFetch(endpoint, {
		baseURL,
		...opts,
	});
}
